<template>
  <main id="tg-main" class="tg-main tg-haslayout">
    <div class="tg-sectionspace tg-haslayout">
      <div class="container">
        <div class="row">
          <div id="tg-twocolumns" class="tg-twocolumns">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div id="tg-content" class="tg-content">
                <div class="tg-newsdetail">
                  <div class="tg-posttitle">
                    <h3>{{ article.title }}</h3>
                  </div>
                  <div class="tg-description" v-html="article.content"> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { SingleQuery } from '../api/commonquery'
export default {
  data() {
    return {
      article: {},
    }
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        switch (this.$route.name) {
          case 'rights':
            this.getData('908462d561a808610145e7b34f20a698')
            break
          case 'game':
            this.getData('908462d561a8078d0145d08476d60382')
            break
        }
      },
    },
  },
  methods: {
    async getData(id) {
      console.log('id', id)
      let res = await SingleQuery({
        model: 'article',
        id,
      })
      this.article = res.data
    },
  },
  created() {
    let query = this.$route.query
    console.log('query', query)
    if (query.id) {
      this.getData(this.$route.query.id)
    } else {
      console.log('无id')
    }
  },
  activated() {
    switch (this.$route.name) {
      case 'rights':
        this.getData('908462d561a808610145e7b34f20a698')
        break
      case 'game':
        this.getData('908462d561a8078d0145d08476d60382')
        break
    }
  },
}
</script>
